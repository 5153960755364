import '../styles/main.css'

// Initialize icons when the DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  // Initialize Lucide icons
  if (window.lucide) {
    window.lucide.createIcons()
  }
  // No else needed - if Lucide isn't loaded, icons simply won't show

  // Add fade-in animation
  const content = document.querySelector('.content')
  content.style.opacity = '0'
  setTimeout(() => {
    content.style.transition = 'opacity 1s ease-in'
    content.style.opacity = '1'
  }, 100)
})

// Add smooth scroll behavior
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()
    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth',
    })
  })
})
